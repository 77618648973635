import React, { PureComponent } from "react";
import { Link } from "react-router-dom";

import * as Service from "../helpers";
import * as date from "../../../utils/date";
import OilsMainPage from "../../../common/OilsMainPage";

type Props = {
  request: any;
};

class Request extends PureComponent<Props> {
  render() {
    const { request } = this.props;
    const backgroundColor = Service.getOilRequestStatusColor(request.status);
    return (

      <div className="requestContainer">
        <div className="column">
          <div>{Service.getOilRequestTitleV2(request)}</div>
        </div>
        <div className="column">
          <div className="requestContainer__label">
            Kütused
          </div>
          <div className="requestContainer__value">
            <OilsMainPage oils={request.oils} />
          </div>
        </div>
        <div className="column">
          <div className="requestContainer__label">
            Staatus
            <span className="ring" style={{ backgroundColor }}></span>
          </div>
          <div className="requestContainer__value"> {Service.getOilRequestStatusName(request.status)}</div>
        </div>
        <div className="column">
          <div className="requestContainer__label">
            Asukoht
          </div>
          <div className="requestContainer__value">{request.county}</div>
        </div>
        <div className="column">
          <div className="requestContainer__label">Hanke lõpp</div>
          <div className="requestContainer__value">{date.get_DD_MM_YYYY(request.endDatetime)}</div>
        </div>
        <div className="column" style={{ height: '50px' }}>
          <Link style={{ textDecoration: 'none' }} to={`/oil-requests/${request.id}`}>
            <a
              href={`/oil-requests/${request.id}`}
              className="requestContainer__details_btn"
            >
              Vaata lähemalt
            </a>
          </Link>
        </div>
      </div>

    );
  }
}

export default Request;
