import React, { PureComponent } from "react";
import classNames from "classnames";
import ReactSelectAsync from "react-select/async";
import ReactSelect, { OptionsOrGroups } from "react-select";
import { ActionMeta, StylesConfig } from "react-select";

function getColor(isSelected: boolean, isFocused: boolean) {
  if (isSelected) {
    return "#FCA800";
  }
  if (isFocused) {
    return "#ebebeb";
  }
  return "#fff";
}

const styles: StylesConfig<any, true> = {
  multiValue: (base, state) => {
    return state.data.isFixed ? { ...base, backgroundColor: 'white', border: '1px solid #e9e9e9' } : base;
  },
  multiValueLabel: (base, state) => {
    return state.data.isFixed
      ? { ...base, color: '#5b5b5b', paddingRight: 6 }
      : base;
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed ? { ...base, display: 'none' } : base;
  },
  option: (base, state) => ({
    ...base,
    color: "#212529",
    fontSize: "16px",
    backgroundColor: getColor(state.isSelected, state.isFocused),
    "&:hover": { backgroundColor: state.isSelected ? "#ffc107" : "#ebebeb" },
  }),
};

type Props = {
  options?: any[];
  value: any;
  name?: string;
  onChange: (
    value: { value: any },
    actionMeta: ActionMeta<{ name?: string }>,
  ) => void;
  isClearable?: boolean;
  placeholder?: string;
  isInvalid?: boolean;
  isMulti?: boolean;
  fontSize?: string;
  loadOptions?: (inputValue: string, callback: (options: any[]) => void) => Promise<any> | void;
  defaultOptions?: OptionsOrGroups<any, any> | boolean;
  noOptionsMessage?: ({ inputValue }) => React.ReactNode;
  isLoading?: boolean;
};

class Select extends PureComponent<Props> {
  static defaultProps = {
    isClearable: true,
    placeholder: "",
    isInvalid: false,
    isMulti: false,
    value: undefined,
    fontSize: '16px'
  };

  private input: any = React.createRef();

  private handleChange = (option, meta) => {
    if (this.input.current) {
      this.input.current.blur();
    }

    this.props.onChange(option, meta);
  };

  private loadingMessage = () => {
    return "Palun oodake...";
  }

  private noOptionsMessage = ({ inputValue }) => `Märksõnale "${inputValue}" ei leitud ühtegi vastet.`;

  render() {
    const { isLoading, options, isClearable, placeholder, isInvalid, isMulti, value, loadOptions, defaultOptions, noOptionsMessage } =
      this.props;

    if (!loadOptions) {
      return (
        <ReactSelect
          className={classNames("select", { "is-invalid": isInvalid })}
          classNamePrefix="select"
          ref={this.input}
          name={this.props.name}
          options={options}
          styles={styles}
          onChange={this.handleChange}
          isClearable={isClearable}
          placeholder={placeholder}
          isMulti={isMulti}
          value={value}
          noOptionsMessage={this.noOptionsMessage}
        />
      );
    }

    return (
      <ReactSelectAsync
        className={classNames("select", { "is-invalid": isInvalid })}
        classNamePrefix="select"
        ref={this.input}
        name={this.props.name}
        options={options}
        styles={styles}
        onChange={this.handleChange}
        noOptionsMessage={noOptionsMessage ? noOptionsMessage : this.noOptionsMessage}
        isClearable={isClearable}
        isLoading={isLoading}
        placeholder={placeholder}
        isMulti={isMulti}
        value={value}
        loadingMessage={this.loadingMessage}
        defaultOptions={defaultOptions}
        loadOptions={loadOptions}
      />
    );
  }
}

export default Select;
