import React, { PureComponent } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Form, Badge, OverlayTrigger, Tooltip } from "react-bootstrap";

import Loader from "../../common/Loader";
import Oils from "../../common/Oils";
import * as date from "../../utils/date";
import * as toastActions from "../../state/toasts";
import * as Service from "../OilRequestsPage/helpers";
import CreateOrEditBid from "./sub/CreateOrEditBid";
import Clock from "./sub/Clock";
import {
  createOilRequestQuestion,
  getOilRequestQuestions,
  answerOilRequestQuestion,
  setOilRequestWinner,
  getOilRequest,
  getOilRequestBids,
} from "../../services/turgoil-api";
import { ToastType } from "../../common/Toasts";
import { RouteComponentProps, withRouter } from "../../common/withRouter";
import OilRequestBidTable from "./sub/OilRequestBidTable";
import StampOilRequestAsChoosingWinner from "./sub/StampOilRequestAsChoosingWinner";
import Icons from "./sub/Icons";
import PageTitle from "../../common/PageTitle";
import TooltipWrapper from "../CreateOilRequestPage/sub/TooltipWrapper";
import PageSectionTitle from "../../common/PageSectionTitle";
import Oil from "../../common/Oil";

moment.locale("et");

type MapStateToProps = {
  user: any;
};

type MapDispatchToProps = {
  showToast: any;
};

type Props = RouteComponentProps<{ id: string }> &
  MapStateToProps &
  MapDispatchToProps;

class OilRequestPage extends PureComponent<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = {
      oilRequest: null,
      oilRequestBids: [],
      loading: false,
      question: "",
      findRequest: "",
      questionErrors: {},
      questionAnswerErrors: {},
      oilRequestQuestions: [],
    };
  }

  componentDidMount() {
    this.getOilRequest();
  }

  getOilRequest = () => {
    const { params } = this.props;
    const { id } = params;

    const promises = [];
    promises.push(getOilRequest(id));
    promises.push(getOilRequestBids(id as any));
    promises.push(getOilRequestQuestions(id));

    this.setState({ loading: true });
    Promise.all(promises)
      .then(([oilRequest, oilRequestBids, oilRequestQuestions]) => {
        this.setState({
          oilRequest,
          oilRequestBids,
          oilRequestQuestions,
          loading: false,
        });
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          loading: false,
        });
      });
  };

  handleOilRequestWinner = (bidId: any, oils?: { id: number, markup: number }[]) => {
    const { showToast } = this.props;

    setOilRequestWinner(this.state.oilRequest.id, {
      bidId,
      oils,
    })
      .then(() => {
        this.getOilRequest();
        showToast({
          title: "Päring õnnestus.",
          text: "Võitja edukalt valitud.",
          type: ToastType.Success,
        });
      })
      .catch(() => {
        // TODO
      });
  };

  getQuestions = () => {
    const { oilRequestQuestions, oilRequest } = this.state;
    const { user } = this.props;

    if (!oilRequestQuestions.length) {
      return (
        <div className="questions__no-questions">Pole ühtegi küsimust</div>
      )
    }

    const listOfQuestions = oilRequestQuestions.map((question, index) => (
      <div key={question.id} className="questions__container">
        <div className="questions__question">
          <div>{index + 1}. {question.question}</div>
          <div></div>
        </div>
        <div className="questions__answer">
          {question.answer && (
            <div className="questions__answer">{question.answer}</div>
          )}
          {!question.answer && oilRequest.status === "Active" && (
            <>
              {Service.canAnswerQuestion(user, oilRequest) ? (
                <div style={{ width: '100%' }}>
                  {this.getAnswerInput(question)}
                </div>
              ) : (
                <div className="questions__answer">
                  Hanke korraldaja pole küsimusele veel vastanud.
                </div>
              )}
            </>
          )}
        </div>
      </div>
    ));

    return listOfQuestions;
  };

  answerQuestion = (e) => {
    const { showToast } = this.props;
    e.preventDefault();

    const { oilRequest } = this.state;
    const questionId = e.target.attributes.getNamedItem("data-value").value;

    if (!this.state[`question${questionId}`]) {
      this.setState({
        questionAnswerErrors: {
          [questionId]: "See väli on kohustuslik.",
        },
      });

      return;
    }
    this.setState({ questionAnswerErrors: {} });

    answerOilRequestQuestion(oilRequest.id, questionId, {
      answer: this.state[`question${questionId}`],
    })
      .then(() => {
        showToast({
          title: "Päring õnnestus.",
          text: "Küsimusele edukalt vastatud!",
          type: ToastType.Success,
        });
        this.getOilRequest();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  getAnswerInput = (question) => {
    const { questionAnswerErrors } = this.state;

    return (
      <div className="questions__answer_form">
        <div>
          <Form.Control
            as="textarea"
            name={`question${question.id}`}
            onChange={this.handleChange}
            className="form-control"
            style={{ width: '100%' }}
            isInvalid={Boolean(questionAnswerErrors[question.id])}
          />
          <Form.Control.Feedback type="invalid">
            {questionAnswerErrors[question.id]}
          </Form.Control.Feedback>
        </div>
        <button
          className="questions__btn"
          type="button"
          data-value={question.id}
          onClick={this.answerQuestion}
        >
          Vasta
        </button>
      </div>
    );
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      errorNotFound: "",
    });
  };

  validateQuestion = () => {
    const { question } = this.state;
    const questionErrors: Record<string, string> = {};

    if (!question) {
      questionErrors.question = "See väli on kohustuslik.";
    }

    this.setState({ questionErrors });

    return Object.keys(questionErrors).length === 0;
  };

  createRequestQuestion = (e) => {
    const { params, showToast } = this.props;
    e.preventDefault();

    if (!this.validateQuestion()) {
      return;
    }

    const { question } = this.state;
    const { id } = params;

    createOilRequestQuestion(id, question.trim())
      .then(() => {
        showToast({
          title: "Päring õnnestus.",
          text: "Küsimus edastatud hankijale!",
          type: ToastType.Success,
        });
        this.getOilRequest();
        this.setState({ question: "" });
      })
      .catch(() => { });
  };

  handleFindSubmit = (e) => {
    e.preventDefault();
    if (!this.state.findRequest) {
      this.setState({
        loading: false,
        errorNotFound: true,
      });
      return;
    }
    getOilRequest(this.state.findRequest)
      .then((oilRequest) => {
        this.setState({
          oilRequest,
          loading: false,
          errorNotFound: false,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
          errorNotFound: true,
        });
      });
  };

  getWinnerChoosingDueDate = (d) =>
    date.getDateTime(moment(d).add(4, "hours").toISOString());

  render() {
    const { user } = this.props;
    const { question, oilRequest, questionErrors, loading, oilRequestBids } =
      this.state;
    let color;
    let size = "lg";
    if (oilRequest) {
      color = Service.getOilRequestStatusColor(oilRequest.status);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      size = "lg";
    }

    return (
      <>
        {loading && <Loader />}
        {!loading && oilRequest && (
          <>
            <div className="oil-request-details">
              <div>
                <Link className="backBtn" to="/oil-requests">
                  {Icons.Back}
                  <span className="backBtn__text">
                    Hanked
                  </span>
                </Link>
              </div>

              <PageTitle>
                {Service.getOilRequestTitle(
                  oilRequest.id,
                  oilRequest.company ? oilRequest.company.id : undefined,
                )}
              </PageTitle>

              <div className="firstSection">
                <div className="firstSection__sub">
                  <PageSectionTitle>Hanke kirjeldus</PageSectionTitle>
                  <div className="firstSection__parent">
                    <div className="firstSection__child">
                      <div className="contact">
                        <div className="contact__row">
                          <div className="oil-request-details__field">
                            <div>
                              {Icons.User}
                            </div>
                            <div className="oil-request-details__field__labelContainer">
                              <div className="oil-request-details__field__labelContainer__label">Korraldaja</div>
                              <div className="oil-request-details__field__labelContainer__value">
                                {oilRequest.company
                                  ? oilRequest.company.name
                                  : oilRequest.user.name}
                              </div>
                            </div>
                          </div>
                          <div className="oil-request-details__field">
                            <div>
                              {Icons.Call}
                            </div>
                            <div className="oil-request-details__field__labelContainer">
                              <div className="oil-request-details__field__labelContainer__label">Telefon</div>
                              <div className="oil-request-details__field__labelContainer__value">
                                {[
                                  oilRequest.company?.contactPhone,
                                  oilRequest.user.contactPhone,
                                ].filter((c) => !!c)[0] || "–"}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="contact__row">
                          <div className="oil-request-details__field">
                            <div>
                              {Icons.Email}
                            </div>
                            <div className="oil-request-details__field__labelContainer">
                              <div className="oil-request-details__field__labelContainer__label">E-posti aadress</div>
                              <div className="oil-request-details__field__labelContainer__value">
                                {[
                                  oilRequest.company?.contactEmail,
                                  oilRequest.user.contactEmail,
                                ].filter((c) => !!c)[0] || "–"}
                              </div>
                            </div>
                          </div>
                          <div className="oil-request-details__field">
                            <div>
                              {Icons.Location}
                            </div>
                            <div className="oil-request-details__field__labelContainer">
                              <div className="oil-request-details__field__labelContainer__label">Asukoht</div>
                              <div className="oil-request-details__field__labelContainer__value">
                                <TooltipWrapper id={undefined} text={oilRequest.addressRegistryId}>
                                  {oilRequest.fullAddress}
                                </TooltipWrapper>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="requestDetails">
                        <div className="requestDetails__row">
                          <div className="oil-request-details__field">
                            <div className="oil-request-details__field__labelContainer">
                              <div className="oil-request-details__field__labelContainer__label">
                                Staatus
                                <span className="ring" style={{ backgroundColor: Service.getOilRequestStatusColor(oilRequest.status) }}></span>
                              </div>
                              <div className="oil-request-details__field__labelContainer__value">
                                {Service.getOilRequestStatusName(oilRequest.status)}
                              </div>
                            </div>
                          </div>
                          <div className="oil-request-details__field">
                            <div className="oil-request-details__field__labelContainer">
                              <div className="oil-request-details__field__labelContainer__label">
                                Transport
                              </div>
                              <div className="oil-request-details__field__labelContainer__value">
                                {Service.getOilRequestTransportName(
                                  oilRequest.transportCode,
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="oil-request-details__field">
                            <div className="oil-request-details__field__labelContainer">
                              <div className="oil-request-details__field__labelContainer__label">
                                Tarnetähtaeg
                              </div>
                              <div className="oil-request-details__field__labelContainer__value">
                                {date.get_DD_MM_YYYY(oilRequest.transportDate)}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="requestDetails__row">
                          <div className="oil-request-details__field">
                            <div className="oil-request-details__field__labelContainer">
                              <div className="oil-request-details__field__labelContainer__label">
                                Hanke lõpp
                              </div>
                              <div className="oil-request-details__field__labelContainer__value">
                                {date.get_DD_MM_YYYY_HH_mm(oilRequest.endDatetime)}
                              </div>
                            </div>
                          </div>
                          <div className="oil-request-details__field">
                            <div className="oil-request-details__field__labelContainer">
                              <div className="oil-request-details__field__labelContainer__label">
                                Aega lõpuni
                              </div>
                              <div className="oil-request-details__field__labelContainer__value">
                                <Clock
                                  request={this.state.oilRequest}
                                  onUpdate={this.getOilRequest}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="oil-request-details__field">
                            <div className="oil-request-details__field__labelContainer">
                              <div className="oil-request-details__field__labelContainer__label">
                                Maksetähtaeg
                              </div>
                              <div className="oil-request-details__field__labelContainer__value" style={{ width: 'fit-content' }}>
                                <TooltipWrapper
                                  id="1" text={date.getDate(oilRequest.paymentDate)}>
                                  <span>{date.getDateDayDifference(oilRequest.paymentDate, oilRequest.transportDate)} päeva</span>
                                </TooltipWrapper>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="oil-request-details__field">
                          <div className="oil-request-details__field__labelContainer">
                            <div className="oil-request-details__field__labelContainer__label">
                              Võitja valimise tähtaeg
                            </div>
                            <div className="oil-request-details__field__labelContainer__value">
                              {this.getWinnerChoosingDueDate(oilRequest.endDatetime)}
                            </div>
                          </div>
                        </div>
                        {oilRequest.description && (
                          <>
                            <div className="oil-request-details__field">
                              <div className="oil-request-details__field__labelContainer">
                                <div className="oil-request-details__field__labelContainer__label">
                                  Kirjeldus
                                </div>
                                <div className="oil-request-details__field__labelContainer__value">
                                  {oilRequest.description}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="firstSection__child">
                      <div className="oilDetails">
                        {oilRequest.oils.map((oil, index) => {
                          return (
                            <Oil oil={oil} key={index}></Oil>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                {Service.canCreateOrEditBid(user, oilRequest) && (
                  <CreateOrEditBid
                    user={user}
                    oilRequest={oilRequest}
                    oilRequestBids={oilRequestBids}
                    updateOilRequest={this.getOilRequest}
                  />
                )}
              </div>

              {oilRequest.winner && (
                <div className="winnerSection">
                  <PageSectionTitle>Hanke võitja</PageSectionTitle>
                  <div className="winnerSection__details">
                    <div className="oil-request-details__field">
                      <div className="oil-request-details__field__labelContainer">
                        <div className="oil-request-details__field__labelContainer__label">
                          Ettevõte
                        </div>
                        <div className="oil-request-details__field__labelContainer__value">
                          {oilRequest.winner.company.name}
                        </div>
                      </div>
                    </div>
                    <div className="oil-request-details__field">
                      <div className="oil-request-details__field__labelContainer">
                        <div className="oil-request-details__field__labelContainer__label">
                          Telefon
                        </div>
                        <div className="oil-request-details__field__labelContainer__value">
                          {[
                            oilRequest.winner.company.contactPhone,
                            oilRequest.winner.user.contactPhone,
                          ].filter((c) => !!c)[0] || "–"}
                        </div>
                      </div>
                    </div>
                    <div className="oil-request-details__field">
                      <div className="oil-request-details__field__labelContainer">
                        <div className="oil-request-details__field__labelContainer__label">
                          E-posti aadress
                        </div>
                        <div className="oil-request-details__field__labelContainer__value">
                          {[
                            oilRequest.winner.company.contactEmail,
                            oilRequest.winner.user.contactEmail,
                          ].filter((c) => !!c)[0] || "–"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <PageSectionTitle>Pakkumised</PageSectionTitle>
              <div>
                <OilRequestBidTable
                  oilRequest={oilRequest}
                  oilRequestBids={oilRequestBids}
                  onWinner={this.handleOilRequestWinner}
                />
              </div>

              <PageSectionTitle>Küsimused</PageSectionTitle>
              <div className="questions">
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  {this.getQuestions()}
                </div>
                {Service.canCreateQuestion(user, oilRequest) && (
                  <>
                    <div >
                      <form onSubmit={this.createRequestQuestion} className="questions__ask_form">
                        <Form.Group>
                          <Form.Label>
                            <div className="questions__ask_form__labelContainer">
                              <div className="questions__ask_form__label">Küsi lisaküsimus</div>
                              <div>
                                <TooltipWrapper
                                  id="1" text="Küsimust näevad pakkujad ja ka hankija.">
                                  {Icons.Info}
                                </TooltipWrapper>
                              </div>
                            </div>
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            value={question}
                            name="question"
                            onChange={this.handleChange}
                            className="form-control"
                            isInvalid={Boolean(questionErrors.question)}
                          />
                          <Form.Control.Feedback type="invalid">
                            {questionErrors.question}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <button className="questions__btn">Saada</button>
                      </form>
                    </div>
                  </>
                )}

              </div>
            </div>
          </>
        )}
        <div className="endRequest">
          <StampOilRequestAsChoosingWinner
            oilRequest={oilRequest}
            onSuccess={this.getOilRequest}
          />
        </div>
      </>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    user: state.userReducer.user,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    showToast: (toast: any) => dispatch(toastActions.showToast(toast)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(OilRequestPage));
