import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "../../../common/Select";
import Icons from "./Icons";
import TooltipWrapper from "./TooltipWrapper";
import { getAddress } from "../../../services/turgoil-api";

export type Address = {
    address: string;
    addressRegistryId?: string;
}

export type AddressOptionGroup = {
    label: string;
    options: AddressOption[];
}

export type AddressOption = {
    value: string;
    label: string;
    valueAddressRegistryId: number;
};

type Props = {
    onChange: (address: Address) => void;
    error?: string;
    requesterOptions?: AddressOptionGroup[];
    value: string;
}

const AddressField: React.FC<Props> = ({
    onChange,
    error,
    requesterOptions,
    value
}): React.ReactElement => {
    const [defaultOptions, setDefaultOptions] = useState<{
        label: string;
        options: {
            value: string;
            label: string;
        }[]
    }[]>([])
    const [isLoading, setLoading] = useState(true);
    const loadOptionsTimeoutRef = useRef(0)
    const loadOptions = (
        inputValue: string,
        callback: (options: any[]) => void
    ) => {
        if (requesterOptions) {
            setDefaultOptions(requesterOptions);
            callback(requesterOptions);
            return;
        }

        window.clearTimeout(loadOptionsTimeoutRef.current);
        loadOptionsTimeoutRef.current = window.setTimeout(() => {
            getAddress(inputValue).then((res) => {
                const addressOptions = {
                    label: '', options: res.data.address.map((a) => ({
                        value: a.address,
                        valueAddressRegistryId: a.addressRegistryId,
                        label: a.address,
                    }))
                };

                setDefaultOptions([addressOptions])
                callback([addressOptions]);
            })
        }, 200);
    }

    useEffect(() => {
        if (!requesterOptions) {
            setLoading(true);
            getAddress(value || "").then((res) => {
                const addressOptions = {
                    label: '', options: res.data.address.map((a) => ({
                        value: a.address,
                        valueAddressRegistryId: a.addressRegistryId,
                        label: a.address,
                    }))
                };
                setLoading(false);
                setDefaultOptions([addressOptions]);
            });
            return;
        }

        setDefaultOptions(requesterOptions)
    }, [requesterOptions, value])

    const handleChange = (option) => {
        if (!option) {
            onChange({ address: "" });
            return;
        }

        onChange({
            address: option.value,
            addressRegistryId: option.valueAddressRegistryId,
        })
    };

    return (
        <Form.Group>
            <Form.Label style={{ marginBottom: '3px' }}>
                <div className="create-request__form-label">
                    <div className="create-request__form-label__text">Asukoht</div>
                    <div>
                        <TooltipWrapper
                            id="7" text={
                                <>
                                    See on asukoht, kuhu toimub tarne.{" "} <br />
                                    Täpsustava informatsiooni lisamiseks lisage see kirjeldusse.
                                </>
                            }>
                            {Icons.Info}
                        </TooltipWrapper>
                    </div>
                </div>
            </Form.Label>
            <Select
                isClearable
                onChange={handleChange}
                placeholder=""
                isInvalid={!!error}
                loadOptions={loadOptions}
                defaultOptions={defaultOptions}
                isLoading={isLoading}
            />
            <Form.Control.Feedback type="invalid">
                {error}
            </Form.Control.Feedback>
        </Form.Group>
    )
};

export default AddressField;
