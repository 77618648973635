import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment, { Moment } from "moment";
import { Alert, Form, Button, Overlay, Popover } from "react-bootstrap";
import Select from "../../common/Select";
import Date from "../../common/Date";
import Loader from "../../common/Loader";
import { createOilRequest } from "../../services/turgoil-api";
import { getDateTime } from "../../utils/date";
import {
  oilRequestTransportOptions,
} from "./helpers";
import { ToastType } from "../../common/Toasts";
import * as toastActions from "../../state/toasts";
import { OilRequestOilType } from "../../utils/config";
import { Oil } from "./sub/OilForm";
import { RootState } from "../../state/store";
import { Dispatch } from "redux";
import Icons from "./sub/Icons";
import TooltipWrapper from "./sub/TooltipWrapper";
import OilRing from "./sub/OilRing";
import PageTitle from "../../common/PageTitle";
import OilFormTable from "./sub/OilFormTable";
import { Bidder } from "../../state/bidder";
import AddressField, { Address, AddressOptionGroup } from "./sub/AddressField";
import { TimeConstraints } from "react-datetime";

moment.locale("et");

type Props = {
  companies: any[];
  bidders: Bidder[];
  user: any;
  showToast: typeof toastActions.showToast;
};

type State = {
  userId: string;
  companyId: string;
  endDatetime: string;
  paymentDateAsNumber: string;
  transportDate: string;
  transportCode: string;
  address: string;
  addressRegistryId?: string;
  description: string;
  termsAccept: boolean;
  errors: Record<string, string>;
  loading: boolean;
  popoverOpen: boolean;
  isSuccess: boolean;
  oils: Oil[];
  nonContractAccess: number[];
  oilsErrors: Record<string, Partial<Record<keyof Oil, string>>>;
};

const oilOptions = [
  {
    value: OilRequestOilType.Petrol95,
    label: "Bensiin 95",
    labelShort: "95"
  },
  {
    value: OilRequestOilType.Petrol98,
    label: "Bensiin 98",
    labelShort: "98"
  },
  {
    value: OilRequestOilType.Diesel,
    label: "Diislikütus",
    labelShort: "D"
  },
  {
    value: OilRequestOilType.DieselSpecial,
    label: "Erimärgistusega diislikütus",
    labelShort: "EDK"
  },
  {
    value: OilRequestOilType.HydrotreatedVegetableOil,
    label: "HVO-biokütus",
    labelShort: "HVO"
  },
];

const timeConstraints: TimeConstraints = {
  hours: {
    min: 9,
    max: 15,
    step: 1
  }
}

class CreateOilRequestPage extends Component<Props, State> {
  private oilRequestRequesterOptions: any;
  private buttonRef: React.RefObject<HTMLButtonElement>;

  constructor(props) {
    super(props);

    this.oilRequestRequesterOptions = this.availableOilRequestRequesters;

    this.state = {
      userId: "",
      companyId: "",
      endDatetime: "",
      paymentDateAsNumber: "",
      transportDate: "",
      transportCode: "",
      address: "",
      addressRegistryId: "",
      description: "",
      oils: [],
      termsAccept: false,
      errors: {},
      oilsErrors: {},
      loading: false,
      popoverOpen: false,
      isSuccess: false,
      nonContractAccess: [],
    };

    this.buttonRef = React.createRef();
  }

  get availableOilRequestRequesters() {
    const { user, companies } = this.props;

    const res = [
      {
        label: "Eraisik",
        options: [
          {
            value: `u${user.id}`,
            label: `${user.forename} ${user.surname}`,
            name: "userId",
          },
        ],
      },
    ];

    if (companies.length) {
      const companyOptions = companies
        .filter((c) => c.confirmed === 1)
        .map((c) => ({
          value: `c${c.id}`,
          label: c.companyName,
          name: "companyId",
        }));
      res[1] = {
        label: "Ettevõtted",
        options: companyOptions,
      };
    }

    return res;
  }

  get getBidders() {
    const { bidders } = this.props;
    const values = bidders
      .map((b) => ({
        value: b.id,
        label: b.name,
        isFixed: b.contract,
      }));

    return values
      .filter((v) => v.isFixed)
      .concat(values.filter((v) => !v.isFixed));
  }

  private handleTransportCodeChange = (option) => {
    if (!option) {
      this.setState((prevState) => ({
        transportCode: null,
        errors: {
          ...prevState.errors,
          transportCode: "",
        },
      }));

      return;
    }

    this.setState((prevState) => ({
      transportCode: option.value,
      errors: {
        ...prevState.errors,
        transportCode: "",
      },
    }));
  };

  private handleOilRequesterChange = (option) => {
    if (!option) {
      this.setState((prevState) => ({
        userId: "",
        companyId: "",
        errors: {
          ...prevState.errors,
          requester: "",
        },
      }));

      return;
    }

    if (option.name === "companyId") {
      this.setState((prevState) => ({
        userId: "",
        companyId: option.value.slice(1),
        errors: {
          ...prevState.errors,
          requester: "",
        },
      }));

      return;
    }

    this.setState((prevState) => ({
      userId: option.value.slice(1),
      companyId: "",
      errors: {
        ...prevState.errors,
        requester: "",
      },
    }));
  };

  private handlePaymentDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { value } = e.target;

    this.setState((prevState) => ({
      paymentDateAsNumber: value,
      errors: {
        ...prevState.errors,
        paymentDateAsNumber: "",
      },
    }));
  };

  private handleTransportDateChange = (date) => {
    let d = date;
    if (d instanceof moment) {
      (d as Moment).set({
        second: 0,
        millisecond: 0,
      });
      d = date.format("YYYY-MM-DD HH:mm:ss");
    }
    this.setState((prevState) => ({
      transportDate: d,
      errors: {
        ...prevState.errors,
        transportDate: "",
      },
    }));
  };

  private handleRequestEndDatetimeChange = (date) => {
    let d = date;
    if (d instanceof moment) {
      (d as Moment).set({
        second: 0,
        millisecond: 0,
      });
      d = date.format("YYYY-MM-DD HH:mm:ss");
    }

    this.setState((prevState) => ({
      endDatetime: d,
      errors: {
        ...prevState.errors,
        endDatetime: "",
      },
    }));
  };

  private handleChange = (e) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      description: value,
      errors: {
        ...prevState.errors,
        description: "",
      },
    }));
  };

  private handleOilChange = (oilChangeEvent: Oil) => {
    this.setState((prevState) => ({
      oils: prevState.oils.map((o) => {
        if (o.oilCode === oilChangeEvent.oilCode) {
          return oilChangeEvent;
        }

        return o;
      }),
      oilsErrors: {
        ...prevState.oilsErrors,
        [oilChangeEvent.oilCode]: {},
      },
      errors: {
        ...prevState.errors,
        oil: "",
      },
    }));
  };

  private renderOilRequestChooseWinnerDatetimeRange = () => {
    const { endDatetime } = this.state;

    const datetime = moment(endDatetime);
    if (datetime.isValid()) {
      const dueDate = moment(datetime).add(4, "hours");
      let dueDateString = dueDate.format("D. MMMM HH:mm");
      if (datetime.isSame(dueDate, "day")) {
        dueDateString = dueDate.format("HH:mm");
      }
      return `${datetime.format("D. MMMM HH:mm")} – ${dueDateString}`;
    }

    return "";
  };

  private renderPaymentDate = (): string | null => {
    const { paymentDateAsNumber, transportDate } = this.state;

    if (!transportDate) {
      return null;
    }

    const date = moment(transportDate);
    (date as Moment).set({
      second: 0,
      millisecond: 0,
    });
    if (date.isValid()) {
      return `Arvutuslik maksetähtaeg: ${date.add(paymentDateAsNumber, "days").format("DD.MM.YYYY")}`;
    }

    return "";
  };

  private togglePopover = () => {
    this.setState((prevState) => ({
      popoverOpen: !prevState.popoverOpen,
    }));
  };


  private validate = (): boolean => {
    const errors: Record<string, string> = {};
    const oilsErrors: Record<string, Partial<Record<keyof Oil, string>>> = {
      [`${OilRequestOilType.DieselSpecial}`]: {},
      [`${OilRequestOilType.Diesel}`]: {},
      [`${OilRequestOilType.Petrol95}`]: {},
      [`${OilRequestOilType.Petrol98}`]: {},
      [`${OilRequestOilType.HydrotreatedVegetableOil}`]: {},
    };

    const requesterAsUserOrCompany =
      !!this.state.userId || !!this.state.companyId;

    if (!requesterAsUserOrCompany) {
      errors.requester = "See väli on kohustuslik!";
    }

    const requestEndDatetime = moment(this.state.endDatetime);
    if (!this.state.endDatetime) {
      errors.endDatetime = "See väli on kohustuslik!";
    } else {
      const maxAllowedDate = moment().add(2, "weeks").endOf("day");
      const minAllowedDate = moment().add(15, "minutes");
      if (!requestEndDatetime.isValid()) {
        errors.endDatetime = "Kuupäeva formaat ei ole korretkne!";
      } else if (requestEndDatetime.isAfter(maxAllowedDate)) {
        errors.endDatetime = `Hanke maksimaalne lubatud lõppkuupäev on ${getDateTime(maxAllowedDate.toISOString())}`;
      } else if (requestEndDatetime.isBefore(minAllowedDate)) {
        errors.endDatetime = `Hanke minimaalne lubatud lõppkuupäev on ${getDateTime(minAllowedDate.toISOString())}`;
      } else if (requestEndDatetime.day() === 0 || requestEndDatetime.day() === 6) {
        errors.endDatetime = "Lõppkuupäev ei tohi olla nädalavahetusel";
      } else if (
        !requestEndDatetime.isSameOrAfter(moment(requestEndDatetime).startOf('day').set('hour', 9).set('minute', 30))
        || !requestEndDatetime.isSameOrBefore(moment(requestEndDatetime).startOf('day').set('hour', 16))
      ) {
        errors.endDatetime = "Lõppkuupäev peab olema vahemikus 9:30-16:00";
      }
    }

    if (this.state.paymentDateAsNumber === undefined) {
      errors.paymentDateAsNumber = "See väli on kohustuslik!";
    } else if (parseInt(this.state.paymentDateAsNumber, 10) < 0) {
      errors.paymentDateAsNumber = "Mäksetähtaeg ei tohi olla minevikus";
    }

    if (!this.state.transportDate) {
      errors.transportDate = "See väli on kohustuslik!";
    } else {
      const transportDate = moment(this.state.transportDate);
      if (!transportDate.isValid()) {
        errors.transportDate = "Kuupäeva formaat ei ole korretkne!";
      } else if (
        requestEndDatetime.isValid() &&
        transportDate.isBefore(requestEndDatetime.startOf("day"))
      ) {
        errors.transportDate =
          "Tarnetähtaeg peab olema hilsem kui hanke lõppkuupäev!";
      }
    }

    if (!this.state.address) {
      errors.address = "See väli on kohustuslik!";
    } else if (
      this.state.transportCode === "Requester" &&
      !this.requesterAddressOptions()[0].options.some(
        (o) => o.valueAddressRegistryId === parseInt(this.state.addressRegistryId, 10),
      )
    ) {
      errors.address = "Asukoht peab olema kas Harju maakond või Tartu maakond.";
    }

    if (!this.state.transportCode) {
      errors.transportCode = "See väli on kohustuslik!";
    }

    if (this.state.termsAccept == false) {
      errors.termsError = "See väli on kohustuslik!";
    }

    this.state.oils.forEach((o) => {
      const amount = parseInt(o.amount, 10) || 0;
      if (amount < 1000) {
        oilsErrors[o.oilCode].amount =
          "Kütuse kogus peab olema vähemalt 1000 L.";
      }
      if (amount > Number.MAX_VALUE) {
        oilsErrors[o.oilCode].amount = "Kütuse kogus ületab lubatud limiidi.";
      }
      // if ([`${OilRequestOilType.Petrol98}`, `${OilRequestOilType.Petrol95}`].includes(o.oilCode) && o.marking === undefined) {
      //   oilsErrors[o.oilCode].marking = "See väli on kohustuslik";
      // }
      // if ([`${OilRequestOilType.Diesel}`, `${OilRequestOilType.DieselSpecial}`].includes(o.oilCode) && o.hasBioAdditive === undefined) {
      //   oilsErrors[o.oilCode].hasBioAdditive = "See väli on kohustuslik";
      // }
      if (
        [`${OilRequestOilType.DieselSpecial}`].includes(o.oilCode) &&
        o.purpose === undefined
      ) {
        oilsErrors[o.oilCode].purpose = "See väli on kohustuslik";
      }
    });
    if (this.state.oils.length === 0) {
      errors.oil = "Vähemalt üks kütus on kohustuslik!";
    }

    this.setState({
      errors,
      oilsErrors,
      isSuccess: false,
    });

    return (
      Object.keys(errors).length === 0 &&
      !Object.values(oilsErrors).some((e) => Object.keys(e).length > 0)
    );
  };

  private handleSubmit = (e) => {
    e.preventDefault();
    const { showToast } = this.props;

    if (!this.validate()) {
      showToast({
        title: "Päring ebaõnnestus",
        text: "Palun parandage vead!",
        type: ToastType.Error,
      });

      return;
    }

    // Show confirmation popup
    this.setState({ popoverOpen: true });
  };

  private continueSubmit = () => {
    const { showToast } = this.props;
    const {
      endDatetime,
      paymentDateAsNumber,
      companyId,
      transportDate,
      transportCode,
      address,
      addressRegistryId,
      description,
      oils,
      nonContractAccess,
    } = this.state;

    this.setState({ loading: true });
    const req: any = {
      endDatetime: moment(endDatetime).format(),
      paymentDate: undefined,
      transportDate,
      transportCode,
      address,
      addressRegistryId,
      description,
      nonContractAccess,
      oils: [
        ...oils
          .filter((o) => parseInt(o.amount, 10) >= 1000)
          .map((o) => ({
            amount: o.amount,
            type: o.oilCode,
            usageType: o.purpose,
            marking: o.marking,
          })),
      ],
    };

    if (companyId) {
      req.companyId = companyId;
    }

    let d = moment(transportDate).add(paymentDateAsNumber, "days");
    (d as Moment).set({
      second: 0,
      millisecond: 0,
    });
    req.paymentDate = d.format("YYYY-MM-DD HH:mm:ss");

    createOilRequest(req)
      .then(() => {
        showToast({
          title: "Päring õnnestus.",
          text: "Õnnitleme, Teie hange on edukalt korraldatud ning ilmub mõne hetke pärast!",
          type: ToastType.Success,
        });

        this.setState({
          isSuccess: true,
          companyId: "",
          userId: "",
          endDatetime: "",
          paymentDateAsNumber: "",
          transportDate: "",
          transportCode: "",
          address: "",
          description: "",
          oils: [],
          termsAccept: false,
          errors: {},
          oilsErrors: {},
          popoverOpen: false,
          loading: false,
        });
      })
      .catch(() => {
        showToast({
          title: "Päring ebaõnnestus.",
          text: "Vabandame, midagi läks valesti. Palun proovi mõne aja pärast uuesti.",
          type: ToastType.Error,
        });

        this.setState({
          loading: false,
          companyId: "",
          endDatetime: "",
          paymentDateAsNumber: "",
          transportDate: "",
          transportCode: "",
          address: "",
          description: "",
          oils: [],
          termsAccept: false,
          popoverOpen: false,
          errors: {},
          oilsErrors: {},
        });
      });
  };

  private validateRequestEndDatetime = (currentDate) => {
    const todayAfterTwoWeeks = moment().add(2, "weeks");
    return (
      currentDate.isBefore(todayAfterTwoWeeks) &&
      currentDate.isSameOrAfter(moment().startOf("day"))
    );
  };

  private handleOilSelect = (oils) => {
    this.setState((prevState) => ({
      oils: oils.map((o) => {
        const existingOil = prevState.oils.find(
          (prevOil) => prevOil.oilCode === o.value,
        );
        if (existingOil) {
          return existingOil;
        }

        return {
          oilCode: o.value,
          amount: "",
        };
      }),
    }));
  };

  private handleBidderSelect = (bidders) => {
    this.setState({
      nonContractAccess: bidders.filter((b) => !b.isFixed).map((b) => b.value),
    });
  };

  private handleTermsToggle = () => {
    this.setState((prevState) => ({
      termsAccept: !prevState.termsAccept,
      errors: {
        ...prevState.errors,
        termsError: "",
      },
    }));
  };

  private handleAddressChange = (address: Address) => {
    if (!address.address) {
      this.setState((prevState) => ({
        address: "",
        addressRegistryId: "",
        errors: {
          ...prevState.errors,
          address: "",
        },
      }));

      return;
    }

    this.setState((prevState) => ({
      address: address.address,
      addressRegistryId: address.addressRegistryId,
      errors: {
        ...prevState.errors,
        address: "",
      },
    }));
  };

  private requesterAddressOptions = (): AddressOptionGroup[] => {
    return [{
      label: '',
      options: [{
        value: 'Harju maakond',
        label: 'Harju maakond',
        valueAddressRegistryId: 100002,
      }, {
        value: "Tartu maakond",
        label: "Tartu maakond",
        valueAddressRegistryId: 2822791,
      }]
    }]
  }

  private isCalendarDateValid = (day: any) => {
    const yesterday = moment().subtract(1, "day");
    if (!day.isAfter(yesterday)) {
      return false;
    }
    if (day.day() === 0 || day.day() === 6) {
      return false;
    }
    if (moment().isSame(day, 'day') && !moment().isBefore(moment('15:45', 'HH:mm'))) {
      return false;
    }

    return true;
  };

  private getOilRequestInitialEndDateTime = () => {
    const currentPlus90 = moment().add(90, "minutes");
    const currentMorning = moment('9:30', 'HH:mm');
    const currentEvening = moment('16:00', 'HH:mm');

    if (
      currentPlus90.isSameOrAfter(currentMorning)
      && currentPlus90.isSameOrBefore(currentEvening)
    ) {
      return currentPlus90;
    }
    if (moment().isBefore(moment('15:45', 'HH:mm'))) {
      return currentEvening;
    }

    return currentMorning;
  };


  render() {
    const { companies } = this.props;
    const { oils, oilsErrors, nonContractAccess } = this.state;

    return (
      <>
        <div style={{ maxWidth: '831px' }}>
          <Helmet>
            <title>Turgoil - Uus hange</title>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
          </Helmet>
          <PageTitle>Uus hange</PageTitle>
          {this.state.loading && <Loader />}
          {!this.state.loading && (
            <Form onSubmit={this.handleSubmit}>
              {!companies.length && (
                <Alert variant="warning">
                  Tundub, et Teil ei ole lisatud ühtegi ettevõtet. Juhul kui
                  soovite korraldada hanget juriidilise isikuna, siis ettevõtteid
                  saate lisada{" "}
                  <Link to="/settings" className="alert-link">
                    seadete lehel
                  </Link>
                  .
                </Alert>
              )}
              <div className="create-request">
                <div className="firstSection">
                  <Form.Group>
                    <Form.Label style={{ marginBottom: '3px' }}>
                      <div className="create-request__form-label">
                        <div className="create-request__form-label__text">Hanke korraldaja</div>
                        <div>
                          <TooltipWrapper
                            id="1" text="Hanke korraldajat näevad ainult pakkujad, mis aitab neil pakkumust paremini koostada.">
                            {Icons.Info}
                          </TooltipWrapper>
                        </div>
                      </div>
                    </Form.Label>
                    <Select
                      isClearable
                      onChange={this.handleOilRequesterChange}
                      placeholder=""
                      options={this.oilRequestRequesterOptions}
                      isInvalid={Boolean(this.state.errors.requester)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.requester}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="secondSection">
                  <div className="secondSection__container">
                    <div className="secondSection__row">
                      <div className="secondSection__field">
                        <Form.Group>
                          <Form.Label style={{ marginBottom: '3px' }}>
                            <div className="create-request__form-label">
                              <div className="create-request__form-label__text">Hanke lõppkuupäev</div>
                              <div>
                                <TooltipWrapper
                                  id="2" text='See on kuupäev, millal hange läheb 
                                "võitja valimise" etappi. Pärast seda ei ole võimalik 
                                pakkumisi teha ega muuta.'>
                                  {Icons.Info}
                                </TooltipWrapper>
                              </div>
                            </div>
                          </Form.Label>
                          <Date
                            onChange={this.handleRequestEndDatetimeChange}
                            initialViewDate={this.getOilRequestInitialEndDateTime()}
                            isInvalid={Boolean(this.state.errors.endDatetime)}
                            isValidDate={this.isCalendarDateValid}
                            timeConstraints={timeConstraints}
                          />
                          <Form.Control.Feedback type="invalid">
                            {this.state.errors.endDatetime}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                      <div className="secondSection__field">
                        <Form.Group >
                          <Form.Label style={{ marginBottom: '3px' }}>
                            <div className="create-request__form-label">
                              <div className="create-request__form-label__text">Tarnetähtaeg</div>
                              <div>
                                <TooltipWrapper
                                  id="3" text='See on kuupäev, millal toimub tarne.'>
                                  {Icons.Info}
                                </TooltipWrapper>
                              </div>
                            </div>
                          </Form.Label>
                          <Date
                            onChange={this.handleTransportDateChange}
                            timeFormat={false}
                            isInvalid={Boolean(this.state.errors.transportDate)}
                          />
                          <Form.Control.Feedback type="invalid">
                            {this.state.errors.transportDate}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    </div>
                    <div className="secondSection__row">
                      <div className="secondSection__field">
                        <Form.Group >
                          <Form.Label style={{ marginBottom: '3px' }}>
                            <div className="create-request__form-label">
                              <div className="create-request__form-label__text">Maksetähtaeg</div>
                              <div>
                                <TooltipWrapper
                                  id="4" text={
                                    <>
                                      Numbriline väärtus päevades, millal olete võimelised
                                      tasuma hanke võitjale kütuse eest. Maksetähtaeg sõltub tarnetähtajast. {this.renderPaymentDate()}
                                    </>
                                  }>
                                  {Icons.Info}
                                </TooltipWrapper>
                              </div>
                            </div>
                          </Form.Label>
                          <Form.Control
                            type="number"
                            onChange={this.handlePaymentDateChange}
                            name="paymentDateAsNumber"
                            isInvalid={!!this.state.errors.paymentDateAsNumber}
                          />
                          <Form.Control.Feedback type="invalid">
                            {this.state.errors.paymentDateAsNumber}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                      <div className="secondSection__field">
                        <Form.Group>
                          <Form.Label style={{ marginBottom: '3px' }}>
                            <div className="create-request__form-label">
                              <div className="create-request__form-label__text">Transport</div>
                              <div>
                                <TooltipWrapper
                                  id="4" text={
                                    <>
                                      Kui transport on võitja poolt, siis toob hanke võitja
                                      kütuse soovitud asukohta. Kui transport on korraldaja
                                      poolt, siis peab korraldaja ise kütusele järgi minema.
                                    </>
                                  }>
                                  {Icons.Info}
                                </TooltipWrapper>
                              </div>
                            </div>
                          </Form.Label>
                          <Select
                            isClearable
                            onChange={this.handleTransportCodeChange}
                            placeholder=""
                            options={oilRequestTransportOptions}
                            isInvalid={Boolean(this.state.errors.transportCode)}
                          />
                          <Form.Control.Feedback type="invalid">
                            {this.state.errors.transportCode}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                  <div>
                    <Form.Group className="secondSection__blueContainer">
                      <Form.Label style={{ marginBottom: '3px' }}>
                        <div className="create-request__form-label">
                          <div className="create-request__form-label__text"><b>Võitja valimise ajavahemik &nbsp;</b></div>
                          <div>
                            <TooltipWrapper
                              id="3" text={
                                <>
                                  Selles ajavahemikus tuleb hankele võitja valida.
                                  <br /><br />
                                  <strong>
                                    NB! Kui hankele ei ole tehtud ühtegi pakkumist, siis
                                    jääb "võitja valimise" etapp vahele.
                                  </strong>
                                </>
                              }>
                              {Icons.Info}
                            </TooltipWrapper>
                          </div>
                        </div>
                      </Form.Label>
                      <div className="create-request__time-duration">
                        {this.renderOilRequestChooseWinnerDatetimeRange()}
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="thirdSection">
                  <AddressField
                    onChange={this.handleAddressChange}
                    error={this.state.errors.address}
                    requesterOptions={this.state.transportCode === "Requester" ? this.requesterAddressOptions() : undefined}
                    value={this.state.address}
                  />
                </div>
                <div className="fourthSection">
                  <Form.Group>
                    <Form.Label style={{ marginBottom: '3px' }}>
                      <div className="create-request__form-label">
                        <div className="create-request__form-label__text">Kirjeldus</div>
                        <div>
                          <TooltipWrapper
                            id="7" text={
                              <>
                                Sisestage siia sobiv kirjeldus, mis aitaks pakkujatel
                                paremini pakkumisi teha. Näiteks võite siin täpsustada
                                tarne asukohta ja tarne tähtaega. Kui Teil on vaja kütust
                                teatud kuupäevadel, siis siin saab seda täpsustada. Hanke
                                kirjeldust näevad ainult pakkujad.
                                <br /><br />
                                <strong>
                                  NB! Kontaktandmete jagamine on rangelt keelatud.
                                </strong>
                              </>
                            }>
                            {Icons.Info}
                          </TooltipWrapper>
                        </div>
                      </div>
                    </Form.Label>
                    <textarea
                      name="description"
                      onChange={this.handleChange}
                      spellCheck="false"
                      className="form-control"
                    />
                  </Form.Group>
                </div>
                <div className="fourthSection">
                  <Form.Group className="col-sm-12 mb-2">
                    <Form.Label style={{ marginBottom: '3px' }}>
                      <div className="create-request__form-label">
                        <div className="create-request__form-label__text">Kaasa täiendavaid pakkujaid</div>
                        <div>
                          <TooltipWrapper
                            id="241234234" text={
                              <>
                                Kutsuge hankega osalema täiendavaid (lepinguväliseid) pakkujaid, kes
                                vastavad hanke nõuetele.
                                <br /><br />
                                Funksiooni abil saate saata personaalse kutse pakkujatele, kutsudes neid
                                osalema konkreetses hankes.
                                <br /><br />
                                Tähelepanu!
                                <br />
                                Veenduge, et kutsutavad pakkujad vastavad kvaliteedi- ja sobivuskriteeriumitele.
                                Kutsutud pakkujate kaasamine on nähtav ainult hanke algatajale.
                              </>
                            }>
                            {Icons.Info}
                          </TooltipWrapper>
                        </div>
                      </div>
                    </Form.Label>
                    <Select
                      options={this.getBidders}
                      onChange={this.handleBidderSelect}
                      value={this.getBidders.filter((b) =>
                        nonContractAccess.some((n) => n === b.value)
                        || b.isFixed
                      )}
                      placeholder=""
                      isMulti
                      isClearable={!!nonContractAccess.length}
                    />
                  </Form.Group>
                </div>
                <div className="fifthSection">
                  <Form.Group>
                    <Form.Label style={{ marginBottom: '3px' }}>
                      <div className="create-request__form-label">
                        <div className="create-request__form-label__text">Kütused</div>
                        <div>
                          <TooltipWrapper
                            id="8" text={
                              <>
                                Valige kütus/kütused, mida soovite tellida.
                              </>
                            }>
                            {Icons.Info}
                          </TooltipWrapper>
                        </div>
                      </div>
                    </Form.Label>
                    <div className="fifthSection__buttons">
                      {oilOptions.map((option: any, index) => (
                        <OilRing
                          key={index}
                          letters={option.labelShort}
                          isSelected={oils.some((o) => o.oilCode === option.value)}
                          onClick={() => {
                            const index = oils.findIndex(item => item.oilCode === option.value);
                            if (index !== -1) {
                              // If the object exists, remove it from the list 
                              const alreadySelected = oilOptions.filter(item1 => oils.some(item2 => item2.oilCode === item1.value));
                              this.handleOilSelect(alreadySelected.filter(item => item.value !== option.value));
                            } else {
                              // If the object does not exist, add it to the list
                              const alreadySelected = oilOptions.filter(item1 => oils.some(item2 => item2.oilCode === item1.value));
                              this.handleOilSelect([...alreadySelected, option])
                            }
                          }
                          } />))}
                    </div>
                  </Form.Group>
                  <OilFormTable oils={oils} handleOilChange={this.handleOilChange} oilsErrors={oilsErrors}></OilFormTable>
                </div>
                {(this.state.errors.oil) && (
                  <div className="invalid-feedback d-block m-0">
                    {this.state.errors.oil}
                  </div>
                )}
                <div>
                  <Form.Group>
                    <Form.Check>
                      <Form.Check.Input
                        isInvalid={Boolean(this.state.errors.termsError)}
                        type="checkbox"
                        onClick={this.handleTermsToggle}
                        checked={this.state.termsAccept}
                      />
                      <Form.Check.Label>
                        <span>
                          Olen lugenud ja nõustun Turgoil.com&nbsp;
                          <a
                            onClick={() => window.open("https://turgoil.com/terms-and-conditions", "_blank", "noopener noreferrer nofollow")}
                            className="create-request__accept-terms-link"
                          >
                            kasutustingimustega.
                          </a>
                        </span>
                      </Form.Check.Label>
                    </Form.Check>
                  </Form.Group>
                  <div style={{ fontSize: '14px', marginLeft: '25px', fontStyle: 'italic' }}>
                    Pange tähele, et kasutustingimuste, sealhulgas ausa konkurentsi reeglite rikkumine,
                    võib viia rahaliste meetmete
                    rakendamiseni.
                    <span style={{ marginLeft: '5px' }}>
                      <TooltipWrapper
                        id="9" text={
                          <>
                            Kasutustingimusi muudetud seisuga 30.03.2025.
                          </>
                        }>
                        {Icons.Info}
                      </TooltipWrapper>
                    </span>
                  </div>
                </div>
                <div className="create-request__submit">
                  <Button ref={this.buttonRef} className="create-request__submit_btn" type="submit">
                    Korralda hange
                  </Button>
                  <Overlay
                    show={this.state.popoverOpen}
                    onHide={this.togglePopover}
                    target={this.buttonRef}
                    rootClose
                  >
                    <Popover id="popover-basic">
                      <Popover.Header as="h3">
                        Hanke andmete kinnitamine
                      </Popover.Header>
                      <Popover.Body>
                        <p>
                          Palume Teil kinnitada, et eelnevalt vormis esitatud hankeandmed on
                          õiged ja ajakohased. <br /><br />Samuti juhime tähelepanu, et hanget korraldades
                          olete teadlik oma kohustusest valida võitja platvormi kaudu vastavalt
                          kehtivatele reeglitele, isegi juhul, kui hankele laekub vaid üks pakkumine.
                        </p>

                        <Button type="button" variant="light" onClick={this.togglePopover}>
                          Loobu
                        </Button>
                        <Button
                          type="button"
                          variant="warning"
                          className="float-end"
                          onClick={this.continueSubmit}
                        >
                          Kinnitan ja esitan
                        </Button>
                      </Popover.Body>
                    </Popover>
                  </Overlay>
                </div>
              </div>
            </Form >
          )
          }
        </div >
      </>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    companies: state.userCompaniesReducer.companies,
    bidders: state.bidderReducer.bidders,
    user: state.userReducer.user,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    showToast: (toast) => dispatch(toastActions.showToast(toast)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateOilRequestPage);
