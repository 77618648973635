import React, { PureComponent } from "react";
import {
  Button,
} from "react-bootstrap";

import Select from "../../../common/Select";
import { Tabs } from "..";
import { RouteComponentProps, withRouter } from "../../../common/withRouter";
import OilRing from "./OilRing";
import StatusBox from "./StatusBox";
import { OilRequestOrder, OilRequestStatus } from "../../../utils/config";
import PageSectionTitle from "../../../common/PageSectionTitle";
import { getOilRequestStatusColor } from "../helpers";

const options = {
  status: [
    {
      value: "Active",
      label: "Aktiivne",
      status: OilRequestStatus.Active
    },
    {
      value: "Closed",
      label: "Lõppenud",
      status: OilRequestStatus.Closed
    },
    {
      value: "Transport",
      label: "Teostamisel",
      status: OilRequestStatus.Transport
    },
    {
      value: "Decision",
      label: "Võitja valimine",
      status: OilRequestStatus.Decision
    },
  ],
  transport: [
    {
      value: "Requester",
      label: "Hankija",
    },
    {
      value: "Bidder",
      label: "Võitja",
    },
  ],
  county: [
    { value: "Harju maakond", label: "Harju maakond" },
    { value: "Hiiu maakond", label: "Hiiu maakond" },
    { value: "Ida-Viru maakond", label: "Ida-Viru maakond" },
    { value: "Järva maakond", label: "Järva maakond" },
    { value: "Jõgeva maakond", label: "Jõgeva maakond" },
    { value: "Lääne maakond", label: "Lääne maakond" },
    { value: "Lääne-Viru maakond", label: "Lääne-Viru maakond" },
    { value: "Pärnu maakond", label: "Pärnu maakond" },
    { value: "Põlva maakond", label: "Põlva maakond" },
    { value: "Rapla maakond", label: "Rapla maakond" },
    { value: "Saare maakond", label: "Saare maakond" },
    { value: "Tartu maakond", label: "Tartu maakond" },
    { value: "Valga maakond", label: "Valga maakond" },
    { value: "Viljandi maakond", label: "Viljandi maakond" },
    { value: "Võru maakond", label: "Võru maakond" },
  ],
  oil: [
    {
      value: "Petrol95",
      label: "Bensiin 95",
      labelShort: "95"
    },
    {
      value: "Petrol98",
      label: "Bensiin 98",
      labelShort: "98"
    },
    {
      value: "Diesel",
      label: "Diislikütus",
      labelShort: "D"
    },
    {
      value: "DieselSpecial",
      label: "Erimärgistusega diislikütus",
      labelShort: "EDK"
    },
    {
      value: "HydrotreatedVegetableOil",
      label: "HVO-biokütus",
      labelShort: "HVO"
    },
  ],
  orderBy: [
    {
      value: OilRequestOrder.OilRequestEndingAsc,
      label: "Lõppevad enne",
    },
    {
      value: OilRequestOrder.OilRequestRegisteredDesc,
      label: "Viimati lisatud enne",
    },
    {
      value: OilRequestOrder.OilTotalAmountDesc,
      label: "Kogus alates suurimast",
    },
    {
      value: OilRequestOrder.OilTotalAmountAsc,
      label: "Kogus alates väikseimast",
    },
  ]
};


function getArrayFromQuery(queryParameter) {
  if (queryParameter === undefined) {
    return [];
  }

  if (typeof queryParameter === "string") {
    return [queryParameter];
  }

  return queryParameter;
}

type OwnProps = {
  onSearch: any;
  user: any;
  orderBy: any;
};

type Props = OwnProps & RouteComponentProps;

class OilRequestFilters extends PureComponent<Props, any> {
  constructor(props) {
    super(props);

    const { userIds } = props.params;

    let tab: any = Tabs.AllOilRequests;
    if (userIds) {
      tab = Tabs.RequesterOilRequests;
    }


    this.state = {
      tab,
      filter: {
        status: getArrayFromQuery(props.params.status),
        transport: getArrayFromQuery(props.params.transport),
        county: getArrayFromQuery(props.params.county),
        oil: getArrayFromQuery(props.params.oil),
        userIds: getArrayFromQuery(props.params.userIds),
        orderBy: OilRequestOrder.OilRequestRegisteredDesc
      },
    };
  }

  handleFilterRequestStatusChange = (statuses) => {
    this.setState((prevState) => ({
      filter: {
        ...prevState.filter,
        status: statuses.map((status) => status.value),
      },
    }));
  };

  handleFilterTransportChange = (transports) => {
    this.setState((prevState) => ({
      filter: {
        ...prevState.filter,
        transport: transports.map((transport) => transport.value),
      },
    }));
  };

  private handleFilterCountyChange = (counties) => {
    this.setState((prevState) => ({
      filter: {
        ...prevState.filter,
        county: counties.map((county) => county.value),
      },
    }));
  };

  handleFilterOrderByChange = (orderBy) => {
    this.setState((prevState) => ({
      filter: {
        ...prevState.filter,
        orderBy: orderBy.value,
      },
    }));
  };

  handleFilterOilChange = (oils) => {
    this.setState((prevState) => ({
      filter: {
        ...prevState.filter,
        oil: oils.map((oil) => oil.value),
      },
    }));
  };

  handleSearch = () => {
    const { onSearch } = this.props;
    const { filter } = this.state;
    onSearch(filter);
  };

  getOptionValues = (optionName, currentValues) =>
    currentValues.map((value) =>
      options[optionName].find((option) => option.value === value),
    );

  getOptionValue = (currentValue) =>
    options.orderBy.find((option) => option.value === currentValue);

  render() {
    const { filter } = this.state;
    return (
      <>
        <div className="filters">
          <PageSectionTitle>Hanke otsing</PageSectionTitle>
          <div className="filters__container">
            <div className="filters__row1">
              <div className="filters__row1__item1">
                <label className="filters__label">Asukoht</label>
                <Select
                  options={options.county}
                  onChange={this.handleFilterCountyChange}
                  value={this.getOptionValues("county", filter.county)}
                  placeholder=""
                  fontSize="14px"
                  isMulti
                />
              </div>
              <div className="filters__row1__item2">
                <label className="filters__label">Hanke staatus</label>
                <div className="statusSection">
                  {options.status.map((option: any, index) => (
                    <StatusBox
                      key={index}
                      text={option.label}
                      backgroundColor={getOilRequestStatusColor(option.status)}
                      isSelected={filter.status.some((o) => o === option.value)}
                      onClick={() => {
                        const index = filter.status.findIndex(item => item === option.value);
                        if (index !== -1) {
                          // If the object exists, remove it from the list 
                          const alreadySelected = options.status.filter(item1 => filter.status.some(item2 => item2 === item1.value));
                          this.handleFilterRequestStatusChange(alreadySelected.filter(item => item.value !== option.value));
                        } else {
                          // If the object does not exist, add it to the list
                          const alreadySelected = options.status.filter(item1 => filter.status.some(item2 => item2 === item1.value));
                          this.handleFilterRequestStatusChange([...alreadySelected, option])
                        }
                      }
                      } />))}
                </div>
              </div>
            </div>
            <div className="filters__row2">
              <div>
                <label className="filters__label">Kütused</label>
                <div className="ringsSection">
                  {options.oil.map((option: any, index) => (
                    <OilRing
                      key={index}
                      letters={option.labelShort}
                      isSelected={filter.oil.some((o) => o === option.value)}
                      onClick={() => {
                        const index = filter.oil.findIndex(item => item === option.value);
                        if (index !== -1) {
                          // If the object exists, remove it from the list 
                          const alreadySelected = options.oil.filter(item1 => filter.oil.some(item2 => item2 === item1.value));
                          this.handleFilterOilChange(alreadySelected.filter(item => item.value !== option.value));
                        } else {
                          // If the object does not exist, add it to the list
                          const alreadySelected = options.oil.filter(item1 => filter.oil.some(item2 => item2 === item1.value));
                          this.handleFilterOilChange([...alreadySelected, option])
                        }
                      }
                      } />))}
                </div>
              </div>
              <div className="filters__row2__item2">
                <div style={{ width: '100%' }}>
                  <Select
                    onChange={this.handleFilterOrderByChange}
                    value={this.getOptionValue(filter.orderBy)}
                    options={options.orderBy}
                    placeholder=""
                    isClearable={false}
                    fontSize="14px"
                  />
                </div>
              </div>
            </div>
            <div className="filters__row3">
              <Button
                type="button"
                onClick={this.handleSearch}
                className="filters__search_btn"
              >
                Otsi hanget
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(OilRequestFilters);
